window.onload = function () {
  function escapeHTML(s) {
    let n = s;
    n = n.replace(/&/g, '&amp;');
    n = n.replace(/</g, '&lt;');
    n = n.replace(/>/g, '&gt;');
    n = n.replace(/"/g, '&quot;');
    return n;
  }

  function getHotComment() {
    const projectId = $('#project_id').val();
    const url = `${window.Env.getAPIBaseURL}/v2/projects/${projectId}/hot-comment`;
    $.ajax({
      type: 'GET',
      url,
      cache: false,
      dataType: 'json',
    }).then(
      (json, status, xhr) => {
        // コメント0件の場合は204が返ってくる
        if (xhr.status !== 200) {
          return;
        }
        const code = window.location.pathname.split('/')[2];
        $('#hotComment').show();
        const hotCommentDiv = `
<a class="hot-comment-content" href="/project/${code}/communication/supporter/" onclick="window.Geenie?.GaEvent?.sendClickEvent?.('hot_comment');">
    <div class="hot-comment-left">
        <img class="supporting-comment-icon" src="${json.user.image_url}">
    </div>
    <div class="hot-comment-right">
        <div class="hot-comment-header">
            <h3 class="hot-comment-title">注目の応援コメント</h3>・
            <p class="hot-comment-name">${escapeHTML(json.user.user_name)}</p>・
            <p class="hot-comment-date">${json.commented_at}</p>
        </div>
        <p class="hot-comment-message">${escapeHTML(json.message)}</p>
    </div>
</a>`;
        $('#hotComment').append(hotCommentDiv);
      },
      () => {},
    );
  }

  getHotComment();
};
